// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sd from "./Sd.bs.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Data from "../data/Data.bs.js";
import * as Dict from "../util/Dict.bs.js";
import * as Trait from "../data/Trait.bs.js";
import * as React from "react";
import * as TftSet from "../data/TftSet.bs.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Tooltip from "../components/Tooltip.bs.js";
import Memoizee from "memoizee";
import * as UnitImage from "./UnitImage.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TraitName0 from "./TraitName0.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Translation from "../bindings/Translation.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as RitoDescription from "../components/RitoDescription.bs.js";
import * as RitoDescription2 from "../components/RitoDescription2.bs.js";

function TraitTooltip$Description(Props) {
  var trait = Props.trait;
  var set = TftSet.use(undefined);
  var t = Translation.useT(/* Traits */2);
  var info = Dict.get(Data.traits(set), trait);
  if (info === undefined) {
    return null;
  }
  var info$1 = Caml_option.valFromOption(info);
  if (set >= 90 || set === 10 || set === 36) {
    return React.createElement(RitoDescription2.make, {
                desc: t(trait + "_desc")
              });
  }
  var count = info$1.descs;
  var desc;
  if (count !== undefined) {
    var idx = String(Js_math.random_int(0, count));
    desc = t(trait + "_descs_" + idx);
  } else {
    desc = t(trait + "_desc");
  }
  var parts = Trait.getDescriptionRito(info$1.effects, desc);
  return React.createElement(RitoDescription.make, {
              parts: parts
            });
}

var Description = {
  make: TraitTooltip$Description
};

function getContents(set, trait, suffix, rank, activeUnits, traitUnits) {
  var unitsT = Translation.useT(/* Units */1);
  var uc = Belt_Option.flatMap(rank, (function (rank) {
          if (rank > 0) {
            return Sd.traitRankUnitCount(trait, rank, set);
          }
          
        }));
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "text-lg font-medium pb-2"
                }, uc !== undefined ? String(uc) + " " : null, React.createElement(TraitName0.make, {
                      trait: trait + suffix
                    })), React.createElement(TraitTooltip$Description, {
                  trait: trait + suffix
                }), React.createElement("div", {
                  className: "grid gap-2 pt-4 min-w-[120px] grid-cols-[repeat(auto-fill,36px)]"
                }, Belt_Array.map(Belt_SortArray.stableSortBy(Belt_SortArray.stableSortBy(traitUnits, (function (u1, u2) {
                                return Caml.caml_string_compare(unitsT(u1), unitsT(u2));
                              })), (function (u1, u2) {
                            return Caml.caml_int_compare(Sd.unitTier(u1), Sd.unitTier(u2));
                          })), (function (unitId) {
                        return React.createElement(UnitImage.make, {
                                    unitId: unitId,
                                    size: 36,
                                    className: activeUnits.length === 0 || activeUnits.includes(unitId) ? "" : "grayscale",
                                    key: unitId
                                  });
                      }))));
}

var getContents$1 = Memoizee(getContents, {
      primitive: true
    });

function TraitTooltip(Props) {
  var disabled = Props.disabled;
  var onOpen = Props.onOpen;
  var onClose = Props.onClose;
  var trait = Props.trait;
  var rank = Props.rank;
  var activeUnitsOpt = Props.activeUnits;
  var traitUnits = Props.traitUnits;
  var children = Props.children;
  var suffixOpt = Props.suffix;
  var activeUnits = activeUnitsOpt !== undefined ? activeUnitsOpt : [];
  var suffix = suffixOpt !== undefined ? suffixOpt : "";
  var set = TftSet.use(undefined);
  var tmp = {
    title: getContents$1(set, trait, suffix, rank, activeUnits, Belt_Option.getWithDefault(traitUnits, Sd.getTraitUnits(trait, set))),
    children: children
  };
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (onOpen !== undefined) {
    tmp.onOpen = Caml_option.valFromOption(onOpen);
  }
  if (onClose !== undefined) {
    tmp.onClose = Caml_option.valFromOption(onClose);
  }
  return React.createElement(Tooltip.make, tmp);
}

var make = TraitTooltip;

export {
  Description ,
  getContents$1 as getContents,
  make ,
  
}
/* getContents Not a pure module */
