// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Fnv1a from "../bindings/Fnv1a.bs.js";
import * as Global from "../Global.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RitoDescription from "../components/RitoDescription.bs.js";

function replaceVariables(str, effect) {
  var parts = Belt_Array.map(str.split(new RegExp("(@.+?@)", "gm")), Belt_Option.getExn);
  var parsedParts = Belt_Array.map(parts, (function (part) {
          if (part.charAt(0) !== "@") {
            return part;
          }
          var varName = part.slice(1, part.length - 1 | 0);
          if (varName === "MinUnits") {
            return String(effect.minUnits);
          }
          var varName$1 = varName === "LifestealBase" ? "LifeStealBase" : varName;
          var v = Js_dict.get(effect.variables, varName$1);
          var v$1 = v !== undefined ? v : Js_dict.get(effect.variables, "{" + Fnv1a.ritoHash(varName$1) + "}");
          if (v$1 !== undefined) {
            if (v$1 === Math.round(v$1)) {
              return Global.toFixed(0, v$1);
            } else {
              return Global.toFixed(undefined, v$1);
            }
          }
          if (!varName$1.includes("*100")) {
            return "???";
          }
          var vn = varName$1.slice(0, varName$1.length - 4 | 0);
          var vn$1 = Belt_Option.isSome(Js_dict.get(effect.variables, vn)) ? vn : "{" + Fnv1a.ritoHash(vn) + "}";
          return Belt_Option.mapWithDefault(Js_dict.get(effect.variables, vn$1), "???", (function (v) {
                        return Global.toFixed(0, v * 100);
                      }));
        }));
  return parsedParts.join("");
}

function getDescriptionRito(effects, description) {
  var parts = Belt_Array.keep(Belt_Array.map(description.split(new RegExp("(<row>.+?</row><br>|<row>.+?</row>|<expandRow>.+?</expandRow>|<br><br>|<br>)", "gm")), Belt_Option.getExn), (function (x) {
          return x !== "";
        }));
  var filteredParts = [];
  var started = {
    contents: false
  };
  Belt_Array.forEach(Belt_Array.reverse(parts), (function (part) {
          if (started.contents) {
            filteredParts.push(part);
            return ;
          } else if (part === "<br>" || part === "<br><br>") {
            return ;
          } else {
            started.contents = true;
            filteredParts.push(part);
            return ;
          }
        }));
  var parts$1 = Belt_Array.reverse(filteredParts);
  var partIndex = {
    contents: 0
  };
  return Belt_Array.concatMany(Belt_Array.map(parts$1, (function (part) {
                    switch (part) {
                      case "<br>" :
                          return [/* LineBreak */0];
                      case "<br><br>" :
                          return [/* DoubleLineBreak */1];
                      default:
                        if (part.startsWith("<row>") && part.endsWith("</row><br>")) {
                          var str = part.slice(5, part.length - 10 | 0);
                          var e = Belt_Array.get(effects, Caml_int32.mod_(partIndex.contents, effects.length));
                          if (e !== undefined) {
                            partIndex.contents = partIndex.contents + 1 | 0;
                            return [{
                                      TAG: /* Row */11,
                                      _0: RitoDescription.parse(replaceVariables(str, Caml_option.valFromOption(e)))
                                    }];
                          } else {
                            return [];
                          }
                        }
                        if (part.startsWith("<row>")) {
                          var str$1 = part.slice(5, part.length - 6 | 0);
                          var e$1 = Belt_Array.get(effects, partIndex.contents);
                          if (e$1 !== undefined) {
                            partIndex.contents = partIndex.contents + 1 | 0;
                            return [{
                                      TAG: /* Row */11,
                                      _0: RitoDescription.parse(replaceVariables(str$1, Caml_option.valFromOption(e$1)))
                                    }];
                          } else {
                            return [];
                          }
                        }
                        if (part.startsWith("<expandRow>")) {
                          var str$2 = part.slice(11, part.length - 12 | 0);
                          return Belt_Array.map(effects, (function (e) {
                                        return {
                                                TAG: /* Row */11,
                                                _0: RitoDescription.parse(replaceVariables(str$2, e))
                                              };
                                      }));
                        }
                        var e$2 = Belt_Array.getExn(effects, 0);
                        return RitoDescription.parse(replaceVariables(part, e$2));
                    }
                  })));
}

function toRankTraitId(traitId, rank) {
  return traitId + "-" + String(rank);
}

function fromRankTraitId(trid) {
  var split = trid.split("-");
  return [
          Belt_Array.getExn(split, 0),
          Belt_Option.getExn(Belt_Int.fromString(Belt_Array.getExn(split, 1)))
        ];
}

function fromRankTraitId2(trid) {
  var split = trid.split("__");
  return [
          Belt_Array.getExn(split, 0),
          Belt_Option.getExn(Belt_Int.fromString(Belt_Array.getExn(split, 1)))
        ];
}

export {
  replaceVariables ,
  getDescriptionRito ,
  toRankTraitId ,
  fromRankTraitId ,
  fromRankTraitId2 ,
  
}
/* Fnv1a Not a pure module */
